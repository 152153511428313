































































































































import {
  BSidebar,
  BForm,
  BButton,
  BCard,
  BCol,
  BFormCheckbox,
  BDropdown,
  BFormGroup,
  BDropdownItem,
  BFormInput,
  BPagination,
  BFormSelect,
  BRow,
  BTable,
  BSpinner,
} from "bootstrap-vue";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import {
  Processus,
  UpdateProcessusModel,
} from "@/api/models/fichesIncident/processus";
import SearchableVueSelect from "@/components/selects/SearchableVueSelect.vue";
import { errorAlert, successAlert } from "@/libs/sweetAlerts/alerts";
import { ShortEquipe } from "@/api/models/options/equipes/equipe";
import { PaginatedList } from "@/api/models/common/paginatedList";
import { MembreEquipe } from "@/api/models/membreEquipes/membreEquipes";
import { DetailsUtilisateur, ShortUtilisateur } from "@/api/models/utilisateurs/utilisateur";

@Component({
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BFormInput,
    BTable,
    BPagination,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BSidebar,
    BForm,
    BFormGroup,
    BFormCheckbox,
    BSpinner,
    ValidationProvider,
    ValidationObserver,
    SearchableVueSelect,
  },
  name: "update-processus",
})
export default class EditProcessus extends Vue {
  @Prop({ required: true, type: Boolean })
  isUpdateProcessusSidebarActive!: boolean;
  @Prop({ required: true }) processusId!: string;

  loading = false;
  required = required;
  loadingProcessus = false;

  processus: UpdateProcessusModel = {
    nom: "",
    piloteId: "",
    membresId: []
  };

  listUtilisateurs: ShortUtilisateur[] = [];
  totalCountUtilisateur = 0;
  listMembres: ShortUtilisateur[] = [];
  totalCountMembres = 0;


  async created() {
    await this.$http.myressif.processus
      .getById(this.processusId)
      .then(async (res: Processus) => {
        this.processus = {
          nom: res.nom,
          piloteId: res.pilote.id,
          membresId: res.membres.map(x => x.id)
        };
        this.listUtilisateurs.push({
          id: res.pilote.id,
          completeLabel: `${res.pilote.nom} ${res.pilote.prenom}`,
          nom: res.pilote!.nom!,
          prenom: res.pilote!.prenom!,
        })

        res.membres.forEach((membre) => {
          this.listMembres.push({
            id: membre.id,
            completeLabel: `${membre.nom} ${membre.prenom}`,
            nom: membre!.nom!,
            prenom: membre!.prenom!,
          })
        })

        await this.searchUtilisateurs(null);
        await this.searchMembres(null);
      });
    this.loadingProcessus = true;
  }

  async onSelectedProcessus() {
    this.processus.piloteId = null;
  }

  async searchUtilisateurs(params: any) {
    await this.$http.myressif.utilisateurs
    .paginatedList(
        params?.pageNumber ?? 1,
        params?.pageSize ?? 10,
        params?.search ?? "",
        false,
        "Nom",
        false
      )
      .then((response: PaginatedList<ShortUtilisateur>) => {
        this.listUtilisateurs = this.listUtilisateurs!.concat(
            response.items.filter(
              (ri) => !this.listUtilisateurs!.some((lsi) => lsi.id == ri.id)
            )
          )
          .map((x: ShortUtilisateur) => <ShortUtilisateur>{
          ...x,
          completeLabel: `${x.nom} ${x.prenom}`
        });
        this.totalCountUtilisateur = response.totalCount
        }
      )
  }

  
  async searchMembres(params: any) {
    await this.$http.myressif.utilisateurs
    .paginatedList(
        params?.pageNumber ?? 1,
        params?.pageSize ?? 10,
        params?.search ?? "",
        false,
        "Nom",
        false
      )
      .then((response: PaginatedList<ShortUtilisateur>) => {
        this.listMembres = this.listMembres!.concat(
            response.items.filter(
              (ri) => !this.listMembres!.some((lsi) => lsi.id == ri.id)
            )
          )
          .map((x: ShortUtilisateur) => <ShortUtilisateur>{
          ...x,
          completeLabel: `${x.nom} ${x.prenom}`
        });
        this.totalCountMembres = response.totalCount
        }
      )
  }
  
  resetForm() {
    this.processus = {
      nom: "",
      piloteId: "",
      membresId: []
    };
  }

  async submit() {
    this.loading = true;
    await this.$http.myressif.processus
      .update(this.processusId, this.processus)
      .then(
        async (response: any) => {
          successAlert.fire({
            timer: 3500,
            title: "Modification du processus",
            text: "Processus modifiée avec succès",
          });
        },
        (error: any) => {
          if (
            error?.response?.data?.errors &&
            Object.keys(error.response.data.errors).length
          ) {
            errorAlert.fire({
              text: error.response.data.errors[
                Object.keys(error.response.data.errors)[0]
              ],
            });
          } else {
            errorAlert.fire({
              text: error?.response?.data?.detail ?? error.message,
            });
          }
        }
      )
      .finally(() => {
        this.$emit("refresh-processus");
        this.$emit("update:is-update-processus-sidebar-active", false);
        this.loading = false;
      });
  }
}
