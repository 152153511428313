var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-sidebar',{attrs:{"id":"add-new-processus-sidebar","visible":_vm.isAddNewProcessusSidebarActive,"bg-variant":"white","sidebar-class":"sidebar-lg","shadow":"","backdrop":"","no-header":"","right":""},on:{"hidden":_vm.resetForm,"change":function (val) { return _vm.$emit('update:is-add-new-processus-sidebar-active', val); }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hide = ref.hide;
return [_c('div',{staticClass:"d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"},[_c('h5',{staticClass:"mb-0"},[_vm._v("Ajouter un nouveau processus")]),_c('feather-icon',{staticClass:"ml-1 cursor-pointer",attrs:{"icon":"XIcon","size":"16"},on:{"click":hide}})],1),_c('validation-observer',{ref:"createFormValidation",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-form',{staticClass:"p-2",on:{"submit":function($event){$event.preventDefault();return _vm.submit.apply(null, arguments)}}},[_c('validation-provider',{attrs:{"name":"nom du nouveau processus","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',[_c('label',{attrs:{"for":"processus-nom"}},[_vm._v("Nom du processus : "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('b-form-input',{attrs:{"id":"processus-nom","name":"processus-nom","trim":"","placeholder":"Nom"},model:{value:(_vm.newProcessus.nom),callback:function ($$v) {_vm.$set(_vm.newProcessus, "nom", $$v)},expression:"newProcessus.nom"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('validation-provider',{attrs:{"name":"nouveau membre","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',[_c('label',{attrs:{"for":"membre"}},[_vm._v("Pilote : "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('searchable-vue-select',{attrs:{"label":"completeLabel","placeholder":"Choisissez un pilote","options":_vm.listPilotes,"hasNextPage":_vm.listPilotes.length < _vm.totalCountPilotes,"reduce":function (user) { return user.id; },"loadOnCreate":true,"searchable":true},on:{"load-next-page":function (params) { return _vm.searchPilotes(params); }},model:{value:(_vm.newProcessus.piloteId),callback:function ($$v) {_vm.$set(_vm.newProcessus, "piloteId", $$v)},expression:"newProcessus.piloteId"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('validation-provider',{attrs:{"name":"nouveau membre","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',[_c('label',{attrs:{"for":"membre"}},[_vm._v("Membres : "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('searchable-vue-select',{attrs:{"label":"completeLabel","placeholder":"Choisissez un ou plusieurs membres","options":_vm.listMembres,"hasNextPage":_vm.listMembres.length < _vm.totalCountMembres,"reduce":function (user) { return user.id; },"loadOnCreate":true,"searchable":true,"multiple":""},on:{"load-next-page":function (params) { return _vm.searchMembres(params); }},model:{value:(_vm.newProcessus.membresId),callback:function ($$v) {_vm.$set(_vm.newProcessus, "membresId", $$v)},expression:"newProcessus.membresId"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),(_vm.loading)?_c('div',{staticClass:"text-center"},[_c('b-spinner',{attrs:{"label":"Chargement...","variant":"primary"}})],1):_vm._e(),(!_vm.loading)?_c('div',{staticClass:"d-flex mt-2"},[_c('b-button',{staticClass:"mr-2",attrs:{"variant":"primary","type":"submit","disabled":invalid}},[_vm._v(" Ajouter ")]),_c('b-button',{attrs:{"type":"button","variant":"outline-secondary"},on:{"click":hide}},[_vm._v(" Annuler ")])],1):_vm._e()],1)]}}],null,true)})]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }